<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <el-button
        style="float: right"
        type="primary"
        @click="dialogFormVisible = true"
        >添加模版</el-button
      >
    </div>
    <el-dialog title="添加模版" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="模版名称" :label-width="formLabelWidth">
          <el-input
            v-model="form.slotname"
            autocomplete="off"
            placeholder="请填写时段模版名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间间隔" :label-width="formLabelWidth">
          <el-radio v-model="timestep" label="00:15">15分钟</el-radio>
          <el-radio v-model="timestep" label="00:30">30分钟</el-radio>
          <el-radio v-model="timestep" label="01:00">1个小时</el-radio>
        </el-form-item>
        <el-form-item label="起始时间" :label-width="formLabelWidth">
          <el-radio v-model="starttimestep" label="07:00">07:00</el-radio>
          <el-radio v-model="starttimestep" label="08:00">08:00</el-radio>
          <el-radio v-model="starttimestep" label="09:00">09:00</el-radio>
          <el-radio v-model="starttimestep" label="10:00">10:00</el-radio>
        </el-form-item>
        <el-form-item label="结束时间" :label-width="formLabelWidth">
          <el-radio v-model="endtimestep" label="18:00">18:00</el-radio>
          <el-radio v-model="endtimestep" label="19:00">19:00</el-radio>
          <el-radio v-model="endtimestep" label="20:00">20:00</el-radio>
          <el-radio v-model="endtimestep" label="21:00">21:00</el-radio>
        </el-form-item>
        <el-form-item label="时段" :label-width="formLabelWidth">
        <el-button type="primary" @click="addInput">增加时段</el-button>
        <el-descriptions v-for="(item, index) in meetings" :key="index" class="margin-top" :column="4" size="medium" border>
            <el-descriptions-item label="开始">
              <el-time-select
                class="eltimeselect"
                placeholder="起始时间"
                :clearable="false"
                :editable="false"
                popper-class="digoleltimeselect"
                v-model="item.firsttime"
                :picker-options="{
                  start: starttimestep,
                  step: timestep,
                  end: endtimestep
                }">
              </el-time-select>
            </el-descriptions-item>
            <el-descriptions-item label="结束">
              <el-time-select
                class="eltimeselect"
                placeholder="结束时间"
                :clearable="false"
                :editable="false"
                popper-class="digoleltimeselect"
                v-model="item.endtime"
                :picker-options="{
                  start: starttimestep,
                  step: timestep,
                  end: endtimestep,
                  minTime: item.firsttime
                }">
              </el-time-select>
            </el-descriptions-item>
            <el-descriptions-item label="放号"><el-input style="width: 100px;" v-model="item.meetnum" placeholder="输入放号"></el-input></el-descriptions-item>
            <el-descriptions-item label="删除"><el-button type="danger" @click="splicesmeetings(index)">删除</el-button></el-descriptions-item>
        </el-descriptions>
        </el-form-item>
        <el-form-item label="模版描述" :label-width="formLabelWidth">
          <el-input
            v-model="form.des"
            autocomplete="off"
            placeholder="请填写模版描述"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeform()">取 消</el-button>
        <el-button type="primary" @click="addtimeslot()">添 加</el-button>
      </div>
    </el-dialog>
    <!--修改-->
    <el-dialog title="修改模版" :visible.sync="editdialogFormVisible">
      <el-form :model="editform">
        <el-form-item label="模版名称" :label-width="formLabelWidth">
          <el-input
            v-model="editform.slotname"
            autocomplete="off"
            placeholder="请填写时段模版名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="时段" :label-width="formLabelWidth">
        <el-button type="primary" @click="addeditslotandnum">增加时段</el-button>
        <el-descriptions v-for="(item, index) in editform.slotandnum" :key="index" class="margin-top" :column="4" size="medium" border>
            <el-descriptions-item label="开始">
              <el-time-select
                class="eltimeselect"
                placeholder="起始时间"
                :clearable="false"
                :editable="false"
                popper-class="digoleltimeselect"
                v-model="item.firsttime"
                :picker-options="{
                  start: starttimestep,
                  step: timestep,
                  end: endtimestep
                }">
              </el-time-select>
            </el-descriptions-item>
            <el-descriptions-item label="结束">
              <el-time-select
                class="eltimeselect"
                placeholder="结束时间"
                :clearable="false"
                :editable="false"
                popper-class="digoleltimeselect"
                v-model="item.endtime"
                :picker-options="{
                  start: starttimestep,
                  step: timestep,
                  end: endtimestep,
                  minTime: item.firsttime
                }">
              </el-time-select>
            </el-descriptions-item>
            <el-descriptions-item label="放号"><el-input style="width: 100px;" v-model="item.meetnum" placeholder="输入放号"></el-input></el-descriptions-item>
            <el-descriptions-item label="删除"><el-button type="danger" @click="spliceslotandnum(index)">点击删除</el-button></el-descriptions-item>
        </el-descriptions>
        </el-form-item>
        <el-form-item label="模版描述" :label-width="formLabelWidth">
          <el-input
            v-model="editform.des"
            autocomplete="off"
            placeholder="请填写模版描述"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeeditform()">取 消</el-button>
        <el-button type="primary" @click="edittimeslot(editform)">点击保存</el-button>
      </div>
    </el-dialog>
    <!--修改-->
    <el-table
      :data="items"
      style="width: 100%; margin-bottom: 20px"
      row-key="_id"
      border
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="slotname" label="模版名称"> </el-table-column>
      <el-table-column prop="des" label="模版描述"></el-table-column>
      <el-table-column prop="date" label="创建时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleedit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handlePageChange"
      @size-change="handlePageSizeChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      starttimestep:"07:00",
      endtimestep:"18:00",
      timestep:"00:15",
      editdialogFormVisible: false,
      dialogFormVisible: false,
      formLabelWidth: "120px",
      currentPage: 1, // 当前页码
      pageSize: 5, // 每页数据量
      total: 0, // 总数据量
      items: [],
      meetings: [],
      form: {
        name: "",
        rolename: "",
        des: "",
      },
      editform: {
        _id: "",
        slotname: "",
        slotandnum: [],
        des: "",
      },
      checkoptions: [],
      tableData: [],
    };
  },
  methods: {
    checkEmptyFields(slotandnum) {
      if (slotandnum.length === 0) {
        return false; // Return true if slotandnum is empty
      }
      for (let obj of slotandnum) {
          if (!obj.firsttime || !obj.endtime || !obj.meetnum) {
              return false; // Return false if any field is empty
          }
      }
      return true; // Return true if all objects have non-empty fields
    },
    edittimeslot(data){
        this.$api.timeslot.timeslotedit(data).then(res =>{
          this.$message({
            message:res.data.msg,
            type: 'success'
          });
          this.loadItems()
          this.editdialogFormVisible = false;
        })
    },
    closeform(){
        this.dialogFormVisible = false
        this.form = {}
        this.meetings = []
    },
    closeeditform(){
        this.editdialogFormVisible = false
    },
    addInput() {
      this.meetings.push({ firsttime: '', endtime: '', meetnum: '' });
    },
    addeditslotandnum() {
      this.editform.slotandnum.push({ firsttime: '', endtime: '', meetnum: '' });
    },
    spliceslotandnum(num) {
      this.editform.slotandnum.splice(num, 1);
      console.log(num)
    },
    splicesmeetings(num) {
      this.meetings.splice(num, 1);
    },
    handleedit(data) {
      console.log(data)
      this.editdialogFormVisible = true;
      const { _id, slotname, slotandnum, des } = data;
      this.editform._id = _id;
      this.editform.slotname = slotname;
      this.editform.slotandnum = slotandnum;
      this.editform.des = des;
    },
    handleDelete(data) {
      this.$api.timeslot.deletetimeslot(data).then((res) => {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.loadItems();
      });
    },
    addtimeslot() {
      const data = {
        slotname: this.form.slotname,
        slotandnum: this.meetings,
        des: this.form.des,
      };
      const isEmpty = this.checkEmptyFields(this.meetings);
      if(!isEmpty){
        return this.$message({
          message: "开始、结束时间以及放号不能为空",
          type: "warning",
        });
      }
      if(!this.form.slotname){
        return this.$message({
          message: "模版名称不能为空",
          type: "warning",
        });
      }
      this.$api.timeslot.createtimeslot(data).then((res) => {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.loadItems();
        this.form = {};
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadItems();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.loadItems();
    },
    loadItems() {
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$api.timeslot.getpagination(data).then((res) => {
        this.currentPage = res.data.pagination.currentPage;
        this.pageSize = res.data.pagination.pageSize;
        this.total = res.data.pagination.total;
        this.items = res.data.data;
      });
    },
  },
  created() {
    this.loadItems();
  },
};
</script>
<style>
.eltimeselect{
  width: 120px!important;
}
.digoleltimeselect{
  width: 120px!important;
}
.el-descriptions-row .el-descriptions-item__cell .el-date-editor .el-input__inner {
  width: 120px!important;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
}
.margin-top{
  margin-top: 10px;
}
</style>
